import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  iconWrapper: {
    marginRight: 0,
    marginLeft: 0,
  },
  icon: {
    marginRight: 0,
    color: theme.palette.secondary.light,
  },
});
