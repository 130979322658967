import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons';

import { isAuthenticated } from 'services/authentication-service';
import { getMessages } from 'state/selectors';

import useStyles from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Divider = loadable(() => import('@material-ui/core/Divider'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));
const Button = loadable(() => import('@material-ui/core/Button'));

const StyledMenu = loadable(() => import('components/styled-drop-down-menu'));
const NavMessage = loadable(() => import('components/navigation/nav-bar/app-bar-dropdown/message'));
const LogInOutNav = loadable(() => import('components/navigation/nav-bar/app-bar-dropdown/login-out'));
const RegisterNavMessage = loadable(() => import('components/navigation/nav-bar/app-bar-dropdown/register'));

const AppBarDropdown = () => {
  const styles = useStyles();

  const authenticated = isAuthenticated();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [menuMessages, setMenuMessages] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [loggedIn, setLoggedIn] = useState(authenticated);

  useEffect(() => {
    setLoggedIn(authenticated);
    return () => {
      setMenuMessages([]);
    };

    // eslint-disable-next-line
  }, [])

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    if (authenticated) {
      const messages = await getMessages();
      setMenuMessages(messages);
    }
    setTimeout(() => setIsOpen(true), 250);
  };

  return (
      <div className={styles.container}>
        <Hidden smUp>
          <Button
           aria-haspopup="true"
           variant="outlined"
           aria-label={`${loggedIn ? 'account action menu' : 'login / register'}`}
           className={styles.appBarButton}
           onClick={handleClick}
           disabled={disabled}
           size="large"
           endIcon={
              <FontAwesomeIcon icon={faUserCircle} size="2x"/>
           }
          >
           {`${loggedIn ? 'Account' : 'Login'}`}
          </Button>
        </Hidden>
        <Hidden xsDown>
          <Button
           variant="outlined"
           aria-haspopup="true"
           aria-label={'account action menu'}
           className={styles.appBarButton}
           onClick={handleClick}
           disabled={disabled}
           size="large"
           endIcon={
              <FontAwesomeIcon icon={faUserCircle} size="2x"/>
              }
          >
           { `${loggedIn ? 'Account' : 'Login / Register'}`}
          </Button>
        </Hidden>
        <StyledMenu
         id="customized-menu"
         anchorEl={anchorEl}
         keepMounted
         open={isOpen}
         onClose={() => {
           setTimeout(() => {
             setIsOpen(false);
             setAnchorEl(null);
           }, 100);
         }}
        >
        <Grid spacing={2}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >

          {Array.isArray(menuMessages)
            && menuMessages.length > 0 && menuMessages.map((mm, index) => (
            <Grid item xs={11} key={index}>
              <NavMessage {...mm } onAction={() => setIsOpen(false)}/>
            </Grid>
          ))}
          {Array.isArray(menuMessages) && menuMessages.length > 0 && (
            <Grid item xs={11}>
              <Divider variant="middle" />
            </Grid>
          )}

        <Grid item xs={11}>
          <LogInOutNav
            setWaiting={(waiting) => {
              if (waiting) {
                setDisabled(true);
                setIsOpen(false);
              } else {
                setDisabled(false);
              }
            }}
            onAction={() => {
              setIsOpen(false);
              setLoggedIn(!loggedIn);
            }}
            loggedIn={loggedIn}/>
        </Grid>

        {!loggedIn && (
            <Grid item xs={11}>
              <RegisterNavMessage onAction={() => setIsOpen(false)}/>
            </Grid>
        )}
        </Grid>
        </StyledMenu>
  </div>
  );
};

export default AppBarDropdown;
