import React, { useState } from 'react';
import LogInOutModal from 'components/login-out-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import useStyles from './styles';

const LoyaltyNavigation = () => {
  const styles = useStyles();

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ListItem
        button
        onClick={() => setModalOpen(true)}
        >
        <ListItemIcon className={styles.iconWrapper}>
        <FontAwesomeIcon icon={faSignOut} size="2x" className={styles.icon}/>
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
      <LogInOutModal
        loggedIn={true}
        open={modalOpen}
        action={() => setModalOpen(false)}
        />
     </>
  );
};

export default LoyaltyNavigation;
