import React, { useState } from 'react';
import { navigate } from 'gatsby';
import LogInOutModal from 'components/login-out-modal';
import classNames from 'classnames';
import { REGISTRATION_START } from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCircle, faSmilePlus,
  faSignIn, faAngleUp, faAngleDown,
} from '@fortawesome/pro-light-svg-icons';

import {
  ListItem, ListItemText, Collapse, List, ListItemIcon,
} from '@material-ui/core';
import useStyles from '../styles';

const LoginRegisterNavigation = () => {
  const styles = useStyles();
  const [openSubMenu, setSubMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ListItem
        button
        className={classNames(openSubMenu ? styles.opened : '')}
        onClick={() => setSubMenuOpen(!openSubMenu)}
      >
      <ListItemIcon className={styles.iconWrapper}>
        <FontAwesomeIcon icon={faUserCircle} size="2x" className={styles.icon}/>
      </ListItemIcon>
        <ListItemText classes={{ primary: styles.primary }} variant="inset" primary="Account" />
        { openSubMenu
          ? <FontAwesomeIcon icon={faAngleDown} size="2x"/>
          : <FontAwesomeIcon icon={faAngleUp} size="2x"/>
        }
      </ListItem>
      <Collapse
        component="li"
        className={styles.listLinks}
        in={openSubMenu}
        timeout="auto"
        unmountOnExit
      >
        <List dense>
        <ListItem
          button
          onClick={() => setModalOpen(true)}
          className={styles.listLinkItem}
          >
          <ListItemIcon className={styles.iconWrapper}>
            <FontAwesomeIcon icon={faSignIn} size="2x" className={styles.icon}/>
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigate(REGISTRATION_START)}
          className={styles.listLinkItem}
          >
          <ListItemIcon className={styles.iconWrapper}>
            <FontAwesomeIcon icon={faSmilePlus} size="2x" className={styles.icon}/>
          </ListItemIcon>
          <ListItemText primary="Register" />
        </ListItem>
        </List>
      </Collapse>
      <LogInOutModal
        loggedIn={false}
        open={modalOpen}
        action={() => setModalOpen(false)}
        />
    </>
  );
};

export default LoginRegisterNavigation;
