import React from 'react';
import {
  HOME_NAME, SUBSCRIPTION_NAME,
  ADD_NAME, LOYALTY_NAME, ACCOUNT_NAME, CONTACT_NAME,
} from 'constants/navigation';
import LoyaltyNavigation from './loyalty';
import HomeNavigation from './home';
import SubscriptionNavigation from './subscription';
import AddNavigation from './add';
import AccountNavigation from './account';
import ContactNavigation from './contact';

const navigationFactory = (item) => {
  if (item === HOME_NAME) {
    return <HomeNavigation key={item} />;
  }

  if (item === LOYALTY_NAME) {
    return <LoyaltyNavigation key={item} />;
  }

  if (item === SUBSCRIPTION_NAME) {
    return <SubscriptionNavigation key={item} />;
  }

  if (item === ADD_NAME) {
    return <AddNavigation key={item} />;
  }

  if (item === ACCOUNT_NAME) {
    return <AccountNavigation key={item} />;
  }

  if (item === CONTACT_NAME) {
    return <ContactNavigation key={item} />;
  }

  return null;
};

export default navigationFactory;
