import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerPlus } from '@fortawesome/pro-light-svg-icons';
import { CREATE_ROUTE } from 'constants/navigation';
import BaseNavigation from '../base-nav';

import useStyles from '../styles';

const AddNavigation = () => {
  const styles = useStyles();

  return (
        <BaseNavigation
          classes={styles}
          description="Add"
          iconChild={
            <FontAwesomeIcon icon={faLayerPlus} size="2x" className={styles.icon}/>
          }
          navigation={CREATE_ROUTE}
          />
  );
};

export default AddNavigation;
