import React from 'react';
import { ALL_CARDS_ROUTE } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGiftCard } from '@fortawesome/pro-light-svg-icons';
import BaseNavigation from '../base-nav';

import useStyles from '../styles';

const LoyaltyNavigation = () => {
  const styles = useStyles();

  return (
        <BaseNavigation
          classes={styles}
          description="Cards"
          iconChild={
            <FontAwesomeIcon icon={faGiftCard} size="2x" className={styles.icon}/>
          }
          navigation={ALL_CARDS_ROUTE}
          />
  );
};

export default LoyaltyNavigation;
