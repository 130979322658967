import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';
import { features } from 'gatsby-env-variables';

import useCanAddPass from 'hooks/use-can-add-pass';

import factory from 'components/navigation/side-bar/navigation-factory';
import SupportNavigation from 'components/navigation/side-bar/navigation-factory/support';
import LogoutNavigation from 'components/navigation/side-bar/navigation-factory/logout';
import LoginRegisterNavigation from 'components/navigation/side-bar/navigation-factory/login-register';
import ContactNavigation from 'components/navigation/side-bar/navigation-factory/contact';
import ValuesNavigation from 'components/navigation/side-bar/navigation-factory/values';

import { Grid } from '@material-ui/core';

import {
  ACCOUNT_NAME,
  LOYALTY_NAME,
  HOME_NAME,
  ADD_NAME,
  SUBSCRIPTION_NAME,
} from 'constants/navigation';

import useStyles from './styles';

const noSubscriptionNavigation = [HOME_NAME, LOYALTY_NAME, ADD_NAME, SUBSCRIPTION_NAME];
const noSubscriptionCannotAddNavigation = [HOME_NAME, LOYALTY_NAME, SUBSCRIPTION_NAME];
const hasSubscriptionNavigation = [HOME_NAME, LOYALTY_NAME, ADD_NAME, ACCOUNT_NAME];
const hasSubscriptionCannotAddNavigation = [HOME_NAME, LOYALTY_NAME, ACCOUNT_NAME];

const SideNavigation = ({ toggle }) => {
  const styles = useStyles();
  const {
    canAdd,
    hasSub,
    authenticated,
    canAddLoading,
  } = useCanAddPass();

  const [navigationItems, setNavigationItems] = useState([]);
  const { showValues = false } = features;

  useEffect(() => {
    const buildNavItems = async () => {
      if (hasSub) {
        setNavigationItems(canAdd
          ? hasSubscriptionNavigation : hasSubscriptionCannotAddNavigation);
      } else {
        setNavigationItems(canAdd
          ? noSubscriptionNavigation : noSubscriptionCannotAddNavigation);
      }
    };

    if (authenticated && !canAddLoading) {
      buildNavItems();
    }

    // eslint-disable-next-line
  }, [authenticated, canAddLoading]);

  return (
  <div className={classNames(styles.drawerInner, !toggle && styles.drawerPaperClose)}>
    <Grid spacing={2}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
      >

    </Grid>
    <div className={classNames(toggle ? styles.menuContainer : styles.paddMenuContainer)}>
      {authenticated && navigationItems && navigationItems.map(factory)}
      <SupportNavigation />
      <ContactNavigation/>
      { authenticated ? <LogoutNavigation /> : <LoginRegisterNavigation />}
      {showValues && !authenticated && <ValuesNavigation />}
    </div>
  </div>
  );
};

SideNavigation.propTypes = { toggle: bool.isRequired };

export default SideNavigation;
