import React from 'react';
import {
  func,
  bool,
} from 'prop-types';
import classNames from 'classnames';

import { Drawer, Hidden } from '@material-ui/core';
import SideNavigation from 'components/navigation/side-bar/side-navigation';

import useStyles from './styles';

const SideBar = ({ menuOpen, setMenuOpen }) => {
  const styles = useStyles();

  return (
      <>
        <Hidden mdUp>
         <Drawer
            variant="temporary"
            anchor="left"
            open={menuOpen}
            onClose={() => setMenuOpen(!menuOpen)}
            classes={{ paper: styles.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
          <div>
            <SideNavigation toggle={menuOpen}/>
          </div>
        </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(styles.drawerPaper, !menuOpen && styles.drawerPaperClose),
            }}
            open={menuOpen}
          >
            <div>
              <SideNavigation toggle={menuOpen}/>
            </div>
          </Drawer>
        </Hidden>
      </>
  );
};

SideBar.propTypes = {
  menuOpen: bool.isRequired,
  setMenuOpen: func.isRequired,
};

export default SideBar;
