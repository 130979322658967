import React from 'react';
import { SUBSCRIPTION_ROUTE } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import BaseNavigation from '../base-nav';

import useStyles from '../styles';

const SubscriptionNavigation = () => {
  const styles = useStyles();

  return (
        <BaseNavigation
          classes={styles}
          description="Subscription"
          iconChild={
            <FontAwesomeIcon icon={faCreditCard} size="2x" className={styles.icon}/>
          }
          navigation={SUBSCRIPTION_ROUTE}
          />
  );
};

export default SubscriptionNavigation;
