import React, { useState } from 'react';

import SideBar from 'components/navigation/side-bar';
import NavBar from 'components/navigation/nav-bar';

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
        <>
          <NavBar
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            />
            <SideBar
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}/>
        </>
  );
};

export default Navigation;
