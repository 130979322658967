import React from 'react';
import { func, bool } from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import AppBarDropdown from 'components/navigation/nav-bar/app-bar-dropdown';
import { HOME_LOGGED_IN_ROUTE, LANDING_PAGE } from 'constants/navigation';

import { isAuthenticated } from 'services/authentication-service';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';

import {
  AppBar, Hidden, Toolbar, Typography, IconButton,
} from '@material-ui/core';

import useStyles, { useAppBarClasses } from './styles';
import svg from './tl_dark.svg';

const NavBar = ({ menuOpen, setMenuOpen }) => {
  const styles = useStyles();
  const appBarClasses = useAppBarClasses();

  const authenticated = isAuthenticated();

  const homeRoute = authenticated ? HOME_LOGGED_IN_ROUTE : LANDING_PAGE;

  return (
      <AppBar
        elevation={1}
        position="fixed"
        classes={appBarClasses}
        className={classNames(
          styles.appBar, (menuOpen) && styles.appBarShift,
        )}
        >
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open menu"
            onClick={() => setMenuOpen(!menuOpen)}>
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Hidden smUp>
            <Link className={styles.logoText} to={homeRoute}>
              <img
                className={styles.logo}
                src={svg}
                alt="Total Loyalty"
                height="25"
                width="25"
              />
            </Link>
          </Hidden>
          <Hidden xsDown>
            <Link className={styles.logoText} to={homeRoute}>
              <img
                className={styles.logo}
                src={svg}
                alt="Total Loyalty"
                height="25"
                width="25"
              />
              </Link>
          <Typography variant="subtitle1" component="h6">
            <Link className={styles.logoText} to={homeRoute}>
            TOTAL LOYALTY
            </Link>
          </Typography>
          </Hidden>
         <AppBarDropdown />
        </Toolbar>
      </AppBar>
  );
};

NavBar.propTypes = {
  menuOpen: bool.isRequired,
  setMenuOpen: func.isRequired,
};

export default NavBar;
