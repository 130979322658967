import React, { useState } from 'react';
import { bool, func } from 'prop-types';
import { navigate } from 'gatsby';

import { useAuth0 } from '@auth0/auth0-react';
import { getUserEmail, removeUser } from 'apputil/user';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faSignIn } from '@fortawesome/pro-regular-svg-icons';

import {
  Modal, Backdrop, Fade,
  Grid, Typography, FormHelperText, Button,
} from '@material-ui/core';

import { LOGOUT_REDIRECT_URL } from 'apputil/auth-0';
import { LOGOUT } from 'constants/navigation';

import useStyles, { useButtonClasses } from './styles';

const LogInOutModal = ({ loggedIn, open, action }) => {
  const styles = useStyles();
  const buttonClasses = useButtonClasses();

  const [working, setWorking] = useState(false);

  const { loginWithRedirect, logout } = useAuth0();

  return (
    <Modal
       aria-labelledby="transition-modal-title"
       aria-describedby="transition-modal-description"
       className={styles.modal}
       open={open}
       onClose={action}
       closeAfterTransition
       BackdropComponent={Backdrop}
       BackdropProps={{ timeout: 500 }}
     >
       <Fade in={open}>
         <div className={styles.paper}>
           <Grid spacing={2}
               container
               direction="row"
               justifyContent="center"
               alignItems="stretch"
             >
             <Grid item xs={10}>
             <Typography variant="h5" component="p" className={styles.heading}>
               {`${loggedIn ? 'Goodbye for now' : 'Welcome back'}`}
             </Typography>
             </Grid>
             <Grid item xs={10}>
                <Button
                 classes={buttonClasses}
                 variant="outlined"
                 color="secondary"
                 className={styles.button}
                 aria-label={`${loggedIn ? 'LOGOUT' : 'LOGIN'}`}
                 fullWidth
                 disabled={working}
                 onClick={() => {
                   setWorking(true);
                   setTimeout(() => {
                     if (loggedIn) {
                       removeUser();
                       logout({ returnTo: LOGOUT_REDIRECT_URL, appState: {} });
                       navigate(LOGOUT);
                     } else {
                       const email = getUserEmail() || '';
                       loginWithRedirect({
                         prompt: 'select_account',
                         login_hint: email,
                         appState: {},
                       });
                     }
                     action();
                   }, 100);
                 }}
                  >
                  <FontAwesomeIcon
                    icon={loggedIn ? faSignOut : faSignIn}
                    className={styles.icon}
                    size="lg"/>
               {`${loggedIn ? 'LOGOUT' : 'LOGIN'}`}
               </Button>
              <FormHelperText className={styles.helpText}>
                {loggedIn ? 'Exit the application' : 'Login to the application'}
              </FormHelperText>
             </Grid>
          </Grid>
         </div>
       </Fade>
     </Modal>
  );
};

LogInOutModal.propTypes = {
  loggedIn: bool.isRequired,
  open: bool.isRequired,
  action: func.isRequired,
};

export default LogInOutModal;
