import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginLeft: 0,
  },
  logoText: {
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
  container: {
    position: 'absolute',
    right: theme.spacing(2),
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
    marginRight: theme.spacing(0.5),
  },
});
